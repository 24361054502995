import React from "react"

import HeaderOfferings from "../../../components/header_offerings"

export default function DirectEquitiesAdvisory() {
  const heroTitle = "Direct Equities Advisory"

  return (
    <HeaderOfferings heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        We design an actively managed portfolio for you of 15-30 good quality businesses listed on
        the Indian stock market.
      </p>

      <p>
        We have a value-oriented long term investment philosophy, which involves buying fractional
        stakes in well run businesses listed on the Indian stock market at a discount to their fair
        or intrinsic value (i.e. for less than what they are actually worth) and holding these
        stakes for the long term (i.e. a minimum of 3 years unless the business shows signs of
        material deterioration in the intervening holding period).
      </p>

      <p>
        Our investment process will be geared towards investing in companies with the following
        characteristics:
      </p>

      <ul class="list-icon list-icon-check list-icon-colored mt-3">
        <li>
          <strong>A business that we can understand and monitor</strong> without being overly
          reliant on the management team running the business;
        </li>
        <li>
          <strong>A sustainable competitive advantage</strong> - companies that generate sustainable
          return on invested capital because of strong competitive position in their operating
          markets;
        </li>
        <li>
          <strong>Trustworthy management</strong> with a track record - companies with trustworthy,
          sincere and competent management teams with a good history of successfully deploying
          capital;
        </li>
        <li>
          <strong>Good financial health</strong> - companies with manageable financial leverage /
          debt; and
        </li>
        <li>
          <strong>Reasonable valuations</strong> - where our purchase price leaves sufficient margin
          of safety if our investment thesis is proven to be wrong.
        </li>
      </ul>

      <p>
        Our actively managed portfolio will take into account your risk profile and investment
        objectives and to the extent feasible will also have a feature of “skin in the game” with
        co-investment in the same set of companies.
      </p>

      <p>
        We will not engage in buying companies that are the current fancies of the market (they
        typically tend to trade at fancy prices and eventually result in their investors booking
        fancy losses), daily trading, trading in the futures / options / derivatives markets and
        short selling.
      </p>
    </HeaderOfferings>
  )
}
